<template>
  <div class='hotel'>
    <div class="single" v-if="list.length >0">
      <p><i></i>酒店信息</p>
      <ul>
        <li v-for="item in list" :key="item.id">
          <p>{{item.detailKey}}</p> <i></i> <span>{{item.detailValue}}</span>
        </li>
      </ul>
    </div>
    <div class="single" v-if="imgList.length >0">
      <p><i></i>图片展示</p>
      <div class="imgbox">
        <el-image v-for="(img,index) in imgList" :key="index" :src="img" :preview-src-list="imgList">
        </el-image>
      </div>
    </div>
    <div v-if="list.length <=0 && imgList.length <=0">
      <el-empty description="暂无酒店数据" class="hotelEmpty"></el-empty>
    </div>
  </div>
</template>

<script>
import { getHotel } from '@/api'
export default {
  props: {
    id: {
      type: Number,
      default: null
    },
    bannerUrl: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      list: [],
      imgList: []
    };
  },
  created () {

  },
  mounted () {
    // console.log(this.id)
    if (this.id) {
      this.init()
    }
    if (this.bannerUrl) {
      this.imgList = JSON.parse(this.bannerUrl)
    }
    // console.log()
  },
  methods: {
    init () {
      getHotel(this.id).then(res => {
        // console.log(res.code)
        if (res.code === 0) {
          this.list = res.data
        }
      })
    },
  }
};
</script>

<style scoped lang="scss">
.hotel {
  width: 68vw;
  .single {
    width: calc(100%);
    margin-top: 10px;
    > p {
      font-size: 12px;
      display: flex;
      align-items: center;
      margin-left: 4px;
      i {
        display: block;
        width: 5px;
        height: 14px;
        background: #236df5;
        border-radius: 5px;
        margin-right: 4px;
      }
    }
    ul {
      padding: 9px 5px 10px;
      li {
        display: flex;
        align-items: center;
        background: #f3f4f7;
        border-radius: 2px;
        padding: 8px 13px 9px 7px;
        margin-bottom: 3px;
        p {
          white-space: nowrap;
        }
        i {
          display: block;
          width: 1px;
          height: 16px;
          background: #464b64;
          opacity: 0.5;
          margin: 0 7px;
        }
        span {
          font-size: 10px;
          color: #858585;
        }
      }
    }
    .imgbox {
      padding: 8px 0 0 10px;
      .el-image {
        width: 110px;
        height: 70px;
        border-radius: 2px;
        margin-right: 5px;
      }
    }
  }
  ::v-deep.hotelEmpty {
    // width: 100%;
    padding: 50px;
    .el-empty__description {
      display: block !important;
      p {
        font-size: 24px;
      }
    }
  }
}
</style>
