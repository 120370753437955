<template>
  <div>
    <div class='home' v-if="locationErr===false">
    <!-- 11 -->
    <div class="title" v-if="!locationShow && hotellist.indexOf(hotelID)!==-1">
      <div>
        <img src="@/assets/logo.png" alt="">
      </div>
      <h3>莲宿码</h3>
    </div>
    <div class="bsTitle" v-else-if="hotelID=='0104'">
      <div v-if="hotelID=='0104'&&isChinese==false">
        <h3 class="locationEn">{{hotelMsgEn.hotelName?hotelMsgEn.hotelName:(hotelMsgEn.sitename?hotelMsgEn.sitename:hotelMsgEn.siteName)}}</h3>
        <h3 class="locationEn">Sanitary Equipment Info Disclosure</h3>
      </div>
     <div v-else>
      <h3  class="location">{{hotelMsg.hotelName?hotelMsg.hotelName:hotelMsg.sitename}}</h3>
      <h3  class="location">卫生信息公示</h3>
     </div>
    </div>
    <div class="title" v-else>
      <h3  class="location" >{{hotelMsg.hotelName?hotelMsg.hotelName:hotelMsg.sitename}}卫生信息公示</h3>
    </div>
    <div class="content">
      <div class="show" v-if="showCode === '展示码'"></div>
      <div v-else-if="locationShow">
        <el-empty description="">
          <div class="empty">
            <p>感谢您的关注</p>
            <p>目前您已离开{{hotelMsg.sitename}}酒店，无法进行扫码查看！！</p>
          </div>
          <div class="emptybottom">
            <p>监制单位：{{hotelMsg.areaName}}卫生健康局</p>
            <p>技术支持：钛比科技</p>
          </div>
        </el-empty>
      </div>
      <el-tabs v-else-if="!locationShow && hotellist.indexOf(hotelID)!==-1" type="border-card" tab-position="left">
        <el-tab-pane label="卫生">
          <vue-scroll :ops="ops">
            <div class="item">
              <p><i></i>酒店信息</p>
              <ul>
                <li>
                  <span class="sitename" >单位名称：</span><span>{{ hotelMsg.hotelName }}</span>
                </li>
                <li>
                  <span class="adrress">地址：</span><span>{{ hotelMsg.address }}</span>
                </li>
                <li v-if="hotelID !== '0032'">
                  <span class="othername">卫生许可项目：</span><span>住宿</span>
                </li>
                <li>
                  <span class="othername">卫生量化等级：</span><span>{{ hotelMsg.grade|| 'B级' }}</span>
                </li>
                <li v-if="hotellist.indexOf(hotelID)!==-1">
                  <span class="othername">食品安全等级：</span><span>A级</span>
                </li>
                <li v-if="hotellist.indexOf(hotelID)!==-1">
                  <span class="othername">绿色救助通道：</span><span style="color:#11ab23">救护员 有 心肺复苏机 有</span>
                </li>
                <li>
                  <span class="othername">卫生监测信息：</span><span style="color:#11ab23">合格</span>
                </li>
                <li v-if="hotelID === '0032'">
                  <span class="othername">年度检测报告：</span><span>年检报告</span>
                </li>
                <li>
                  <span class="othername">酒店投诉电话：</span><span>{{ hotelMsg.fixedPhone|| hotelMsg.phone }}</span>
                </li>
                <li v-if="hotelID === '0032'">
                  <span class="othername">卫监投诉电话：</span><span>0571-12345</span>
                </li>
              </ul>
            </div>
            <div class="item" v-if="hotellist.indexOf(hotelID)!==-1">
              <p><i></i>空气质量</p>
              <ul>
                <li>
                  等级：<span>优</span>
                </li>
                <li>
                  PM2.5：<span>20 μg/m3</span>
                </li>
                <li>
                  负氧离子：<span>1500 个/cm3</span>
                </li>
              </ul>
            </div>
            <div class="item">
              <p><i></i>保洁信息</p>
              <div class="clean">您现在入住的房间为<span style="color:orange">{{roomId}}</span>房间，最近一次清扫保洁时间为：{{flag?'2022-09-28':lastTime}}</div>
            </div>
            <div class="item">
              <p><i></i>布草更换</p>
              <el-table :data="tableData" border height="180">
                <el-table-column prop="name" label="种类" width="40">
                  <template slot-scope="{row}">
                    <span v-if="row.name.substring(0, 2) === '56'">枕套</span>
                    <span v-if="row.name.substring(0, 2) === '67'">床单</span>
                    <span v-if="row.name.substring(0, 2) === '78'">被套</span>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="布草ID" width="100">
                </el-table-column>
                <!-- <el-table-column prop="outTime" label="清洗时间" v-if="!tableShow">
                  <template slot-scope="{row}">
                    {{flag?row.outTime?`09-28 ${$moment(row.outTime).format('HH:mm')}`:'-':row.outTime?$moment(row.outTime).format('MM-DD HH:mm'):'-'}}
                  </template>
                </el-table-column> -->
                <el-table-column prop="roomCentralizationTime" label="更换时间">
                  <template slot-scope="{row}">
                    <!-- 111 -->
                    {{flag?row.roomCentralizationTime?`09-28 ${$moment(row.roomCentralizationTime).format('HH:mm')}`:'-':row.roomCentralizationTime?$moment(row.roomCentralizationTime).format('MM-DD HH:mm'):'-'}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="item" v-if="cupsDisinfectTime">
              <p><i></i>杯具消毒</p>
              <div class="clean">杯具：<span style="color:#11ab23;margin-right:10px">已消毒</span>消毒时间：{{flag?`2022-09-28 ${$moment(cupsDisinfectTime).format('HH:mm:ss')}`:cupsDisinfectTime}}</div>
            </div>
            <div class="item" v-if="logStoolDishclothTime">
              <p><i></i>洁具清洗</p>
              <div class="clean">洁具：<span style="color:#11ab23;margin-right:10px">已清洁</span>清洁时间：{{flag?`2022-09-28 ${$moment(logStoolDishclothTime).format('HH:mm:ss')}`:logStoolDishclothTime}}</div>
            </div>
          </vue-scroll>
        </el-tab-pane>
        <el-tab-pane label="酒店" v-if="hotelMsg.isHaveDetail">
          <hotel :id="hotelMsg.id" :bannerUrl="hotelMsg.bannerUrl" />
        </el-tab-pane>
        <!-- <div v-if="!hotelMsg.isHaveDetail && !hotelMsg.isHaveService" style="padding: 10px 14px">
          <el-empty>
            <div class="empty">
              <p>感谢您的关注</p>
              <p>酒店暂无展示数据，敬请期待！</p>
            </div>
          </el-empty>
        </div> -->
        <!-- v-if="hotelMsg.isHaveService" -->
      </el-tabs>
      <div v-else class="other">
        <!-- 宝盛宾馆切换中英文switch -->
        <div class="switch" v-if="hotelID=='0104'">
          <div class="switchGroup">
            <div
            class="switchNums"
            :class="{ 'currentNum':isChinese==true }"
            @click="switchChange"
          >
            {{ selectOpen[0] }}
          </div>
          <div
            class="switchNums "
            :class="{'currentNum': isChinese==false }"
            @click="switchChange"
          >
            {{ selectOpen[1] }}
          </div>
          </div>
        </div>
        
        <vue-scroll :ops="ops">
          <div class="item" >
            <p style="font-size: 16px;"><i></i>{{hotelID=='0104'&&isChinese==false?'Hotel Info':'酒店信息'}}</p>
            <ul>
              <li style="font-size: 14px;">
                <span class="sitename" v-show="hotelID!=='0104'||isChinese">单位名称：</span>
                <span style="margin-right: 10px;" v-show="hotelID=='0104'&&isChinese==false">Name:</span>
                <span v-show="hotelID!=='0104'||isChinese">{{ hotelMsg.hotelName?hotelMsg.hotelName:(hotelMsg.sitename?hotelMsg.sitename:hotelMsg.siteName) }}</span>
                <span v-show="hotelID=='0104'&&isChinese==false">{{ hotelMsgEn.hotelName?hotelMsgEn.hotelName:(hotelMsgEn.sitename?hotelMsgEn.sitename:hotelMsgEn.siteName) }}</span>
              </li>
              <li style="font-size: 14px;">
                <span style="margin-right: 10px;display: flex;" v-show="hotelID=='0104'&&isChinese==false">Address:&nbsp;&nbsp;<span style="display: block;">{{ hotelMsgEn.address }}</span></span>
                <span class="adrress" v-show="hotelID!=='0104'||isChinese" style="display: contents">地址：<span style="white-space:initial;vertical-align:text-top;display: inline-block;">{{ hotelMsg.address }}</span></span>
                
              </li>
              <li v-if="hotelID !== '0032'" style="font-size: 14px;">
                <span class="othername" v-show="hotelID!=='0104'||isChinese">卫生许可项目：</span><span v-show="hotelID!=='0104'||isChinese">住宿</span>
                <span style="margin-right: 10px;" v-show="hotelID=='0104'&&isChinese==false">Sanitary Licensing Projects:</span><span v-show="hotelID=='0104'&&isChinese==false"> Accommodation</span>
              </li>
              <li style="font-size: 14px;">
                <span class="othername" v-show="hotelID!=='0104'||isChinese">卫生量化等级：<span>{{ hotelMsg.grade|| 'B级' }}</span></span>
                <span style="margin-right: 10px;" v-show="hotelID=='0104'&&isChinese==false">Sanitary Licensing Projects: <span>A</span></span>
                
              </li>
              <li v-if="hotellist.indexOf(hotelID)!==-1" style="font-size: 14px;">
                <span class="othername">食品安全等级：</span><span>A级</span>
              </li>
              <li v-if="hotellist.indexOf(hotelID)!==-1" style="font-size: 14px;">
                <span class="othername">绿色救助通道：</span><span style="color:#11ab23">救护员 有 心肺复苏机 有</span>
              </li>
              <li style="font-size: 14px;">
                <span class="othername" v-show="hotelID!=='0104'||isChinese">卫生监测信息：</span>
                <span style="margin-right: 10px;" v-show="hotelID=='0104'&&isChinese==false">Sanitary Monitoring Info:</span>
                <span style="color:#11ab23" >{{hotelID=='0104'&&isChinese==false?'Qualified':'合格'}}</span>
              </li>
              <li v-if="hotelID === '0032'" style="font-size: 14px;">
                <span class="othername">年度检测报告：</span><span>年检报告</span>
              </li>
              <li style="font-size: 14px;">
                <span class="othername" v-show="hotelID!=='0104'||isChinese">酒店投诉电话：</span>
                <span style="margin-right: 10px;" v-show="hotelID=='0104'&&isChinese==false">Complaints Hotline:</span>
                <span>{{ hotelMsg.fixedPhone|| hotelMsg.phone }}</span>
              </li>
              <li v-if="hotelID === '0032'" style="font-size: 14px;">
                <span class="othername">卫监投诉电话：</span><span>0571-12345</span>
              </li>
            </ul>
          </div>
          <div class="item" v-if="hotellist.indexOf(hotelID)!==-1">
            <p><i></i>空气质量</p>
            <ul>
              <li>
                等级：<span>优</span>
              </li>
              <li>
                PM2.5：<span>20 μg/m3</span>
              </li>
              <li>
                负氧离子：<span>1500 个/cm3</span>
              </li>
            </ul>
          </div>
          <div class="item">
            <p style="font-size: 16px;"><i></i>{{hotelID=='0104'&&isChinese==false?'Cleaning Info':'保洁信息'}}</p>
            <div class="clean" v-show="hotelID!=='0104'||isChinese">您现在入住的房间为<span style="color:orange">{{roomId}}</span>房间，最近一次清扫保洁时间为：{{lastTime}}</div>
            <div style="padding: 10px 0 5px 15px;line-height: 1.5;font-size: 12px;" v-show="hotelID=='0104'&&isChinese==false">Room Number:<span style="color:orange;margin-left:10px">{{roomId}}</span></div>
            <div style="padding: 0 0 5px 15px;line-height: 1.5;font-size: 12px;" v-show="hotelID=='0104'&&isChinese==false">Last Cleaning Time:<span style="margin-left:10px">{{$moment(lastTime).format('MMMM Do, YYYY')}}</span></div>
          </div>
          <div class="item" v-if="hotelMsg.isHaveCentralization">
            <p style="font-size: 16px;"><i></i>{{hotelID=='0104'&&isChinese==false?'Linen Info':'布草更换'}}</p>
            <el-table :data="tableData" border height="100">
              <el-table-column prop="name" :label="changelabel" :width="nameWidth">
                <template slot-scope="{row}">
                  <span v-if="row.name.substring(0, 2) === '56'">{{hotelID=='0104'&&isChinese==false?'Pillowslip':'枕套'}}</span>
                  <span v-if="row.name.substring(0, 2) === '67'">{{hotelID=='0104'&&isChinese==false?'Bedspreads':'床单'}}</span>
                  <span v-if="row.name.substring(0, 2) === '78'">{{hotelID=='0104'&&isChinese==false?'Quiltcover':'被套'}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="name" :label="changelabel1" width="100">
              </el-table-column>
              <!-- <el-table-column prop="outTime" label="清洗时间" v-if="!tableShow">
                  <template slot-scope="{row}">
                    {{flag?row.outTime?`09-28 ${$moment(row.outTime).format('HH:mm')}`:'-':row.outTime?$moment(row.outTime).format('MM-DD HH:mm'):'-'}}
                  </template>
                </el-table-column> -->
              <el-table-column prop="roomCentralizationTime" :label="changelabel2" >
                <template slot-scope="{row}" >
                  <span v-show="isChinese">
                    {{flag?row.roomCentralizationTime?`09-28 ${$moment(row.roomCentralizationTime).format('HH:mm')}`:'-':row.roomCentralizationTime?$moment(row.roomCentralizationTime).format('MM-DD HH:mm'):'-'}}
                  </span>
                  <span v-show="hotelID=='0104'&&isChinese==false">
                    {{flag?row.roomCentralizationTime?`09-28 ${$moment(row.roomCentralizationTime).format('HH:mm')}`:'-':row.roomCentralizationTime?$moment(row.roomCentralizationTime).format('MMMM Do, HH:mm'):'-'}}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="item" v-if="hotelMsg.isDetection">
            <p style="font-size: 16px;"><i></i>{{hotelID=='0104'&&isChinese==false?'Cup Sterilization Info':'杯具消毒'}}</p>
            <div class="clean" v-if="cupsDisinfectTime">杯具：<span style="color:#11ab23;margin-right:10px">已消毒</span>消毒时间：{{cupsDisinfectTime}}</div>
            <div class="clean" style="padding-left: 15px;" v-else-if="hotelID=='0104'&&isChinese==false">/</div>
            <div class="clean" v-else>暂无</div>
          </div>
          <div class="item" v-if="hotelMsg.isHaveDishcloth">
            <p style="font-size: 16px;"><i></i>{{hotelID=='0104'&&isChinese==false?'Sanitary Ware Cleaning Info':'洁具清洗'}}</p>
            <div class="clean" v-if="logStoolDishclothTime&&isChinese">洁具：<span style="color:#11ab23;margin-right:10px">已清洁</span>清洁时间：{{logStoolDishclothTime}}</div>
            <div class="clean" v-else-if="hotelID=='0104'&&isChinese==false&&logStoolDishclothTime">Status:<span style="color:#11ab23;margin-right:10px">Cleaned </span>Cleaning Time: {{$moment(logStoolDishclothTime).format('MMMM Do, YYYY, HH:mm:ss')}}</div>
            <div class="clean" v-else-if="hotelID=='0104'&&isChinese==false&&!logStoolDishclothTime">/</div>
            <div class="clean" v-else>暂无</div>
          </div>
        </vue-scroll>
      </div>
    </div>
    <div class="footerEn"  v-if="hotelID=='0104'&&isChinese==false">
      Technical Support:Hangzhou Terabits Technology Co.Ltd.
    </div>
    <div class="footer" v-else>
      技术支持：杭州钛比科技有限公司
    </div>
    <p class="goPublic" @click="goPublic" v-if="hotelMsg.appid">
      关注酒店<br />公众号
    </p>
  </div>
  <div class="homeErr" v-else-if="locationErr===true" >
    <div>
      <p>当前扫码超出地理位置限制,</p>
      <p>请在酒店范围内扫码！</p>
    </div>

  </div>
  <div class="homeErr" v-else-if="locationErr==='null'" >
  </div>
  <div class="homeErr" v-else >
    <div>
      <p style="font-weight:bold ;">未授权地理位置信息将无法</p>
      <p style="font-weight:bold ;">为您提供当前房间的清洁卫生信息</p>
      <p style="font-weight:bold ;">请重新扫码授权位置信息！</p>
      <p style="font-weight:bold ;">并确认浏览器定位权限！</p>
    </div>

  </div>
  </div>
</template>

<script>
import { getData } from '../../api'
import Hotel from './components/hotel.vue'
import AMap from 'AMap'
export default {
  components: { Hotel },
  data () {
    return {
      alldata:{},
      hotelMsgEn:{},
      isChinese:true,
      selectOpen:['中文','English'],
      // 滚动条参数
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {
          keepShow: true,
        },
        bar: {
          hoverStyle: true,
          onlyShowBarOnScroll: false, //是否只有滚动的时候才显示滚动条
          background: '#999999', //滚动条颜色
          opacity: 0, //滚动条透明度
          'overflow-x': 'hidden',
          minSize: 0.1
        },
        videoData: [],
      },
      // 定位超过100米
      locationShow: false,
      // 不同表格展示
      tableShow: false,
      // 展示码
      showCode: '',
      // 酒店id
      hotelID: '',
      // 房间id
      roomId: '',
      // 酒店信息
      hotelMsg: {},
      // 空气质量
      airMsg: {},
      // 保洁信息
      lastTime: '',
      // 布草更换
      tableData: [],
      // 杯具消毒时间
      cupsDisinfectTime: '',
      // 洁具清洗时间
      logStoolDishclothTime: '',
      // 莲都酒店
      hotellist: [
        "0023", "0024", "0073", "0068", "0078", "0066", "0084", "0085", "0087", "0086", "0088", "0089", "0090", "0064", "0065", "0067", "0069", "0071", "0072", "0074", "0075", "0076", "0077", "0080", "0081"
        , "0082", "0083", "0091", "0092", "0093", "0070", "0079"
      ],
      // 假数据开关
      flag: false,
      locationErr:'null'
    };
  },
  computed:{
    changelabel(){
      return this.hotelID=='0104'&&this.isChinese==false?'Category':'种类'
    },
    changelabel1(){
      return this.hotelID=='0104'&&this.isChinese==false?'ID':'布草ID'
    },
    changelabel2(){
      return this.hotelID=='0104'&&this.isChinese==false?'Replacement Time':'更换时间'
    },
    nameWidth(){
      return this.hotelID=='0104'&&this.isChinese==false?'75':'40'
    }
  },
  created () {
    // if (this.$route.query.roomId === '酡颜') {
    //   this.flag = true
    // } else {
    //   this.flag = false
    // }
    this.init()
    // console.log(this.$route.query.roomId)
  },
  mounted () {
    // this.init()
  },
  methods: {
    switchChange() {
       this.isChinese = !this.isChinese
       console.log('isChinese', this.isChinese)
     },
    async init () {
      
      this.showCode = this.getQueryVariable('roomId')
      this.hotelID = this.getQueryVariable('hotelID')//"0104" 
      this.roomId = this.getQueryVariable('roomId')//'408'
      
      const location = await this.getLocationNew()
      // const location ={'longitude':120.124594,'latitude':30.277467}
      getData(this.hotelID, this.roomId,location).then(res => {
        // console.log(res, 2222)
        if (res.cmd!=0) {
          this.$message.error(res.cmd)
          this.locationErr=res.cmd.includes('地理')
          return
        }
        if(res.cmd=='0'){
          this.locationErr=false
        }
        if(this.getQueryVariable('hotelId')=='0253'||this.getQueryVariable('hotelID')=='0253'){
        this.$router.push('/assessment')
        this.$router.push({path:'/assessment',query:{hotelId:'2410',roomId:this.roomId,longitude:location.longitude,latitude:location.latitude}})
        return
        }
        const hotleIDList = ['0006', '0007', '0023', '0024', '0032']
        if (this.hotellist.indexOf(this.hotelID) !== -1) {
          document.getElementsByTagName("title")[0].innerText = '莲宿码';
        } else {
          document.getElementsByTagName("title")[0].innerText = '卫生信息公示';
        }
        const { data } = res
        if (data.language) {
          this.hotelMsgEn = data.language
          this.hotelMsg = data.hotel
        }else{
          this.hotelMsg = data.hotel
        }
        if (this.hotelMsg.isFixPosition) {
          this.getLocation()
        }
        if (hotleIDList.find(el => el === this.hotelID)) {
          this.tableShow = true
        }
        switch (this.hotelMsg.hotelName) {
          case '亚朵西湖文化广场店':
            this.hotelMsg.hotelName = '亚朵西湖文化广场店(杭州天籁假日酒店)'
            break;
          case '亿泊城市艺术酒店':
            this.hotelMsg.hotelName = '亿泊城市艺术酒店(杭州亿金酒店管理有限公司)'
            break;
          case '丽水隐居画乡院落酒店':
            this.hotelMsg.hotelName = '丽水隐居酒店有限公司'
            break;
          default:
            break;
        }
        this.lastTime = data.lastTime ? this.$moment(data.lastTime).format('YYYY-MM-DD') : '暂无'
        this.tableData = data.logTextile
        if (data.cups && data.cups.length > 0 && data.cups[0].cupsDisinfectTime) {
          this.cupsDisinfectTime = data.cups[0].cupsDisinfectTime
        }
        this.logStoolDishclothTime = data.logStoolDishclothTime
        // console.log('this.tableData :>> ', this.tableData);
        // if (this.flag) {
          // this.tableData.push({
          //   centralizationDevice: "a2bf1fb1ce18b92d",
          //   hotelId: 529,
          //   hotelRoomId: 400,
          //   id: 439343,
          //   inTime: "2022-03-28 12:35:36",
          //   isReplaceUpStandard: true,
          //   lastRoomCentralizationTime: "2021-09-24 15:58:28",
          //   lastStatus: 1,
          //   name: "780023000168",
          //   outTime: "2022-03-16 10:12:57",
          //   roomCentralizationTime: "2022-09-20 08:50:55",
          //   roomId: "酡颜",
          //   type: "78"
          // })
        // }
        // console.log(Boolean(this.cupsDisinfectTime))
    
        // console.log(this.$refs.hotel)
        // this.$refs.hotel.init(this.hotelMsg.id)
      })
    },
    // 截取地址字段
    getQueryVariable (variable) {
      if (window.location.search) {
        const arr = window.location.search.split("?")
        var vars = arr[1].split("&");
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=");
          if (pair[0] == variable) { return decodeURIComponent(pair[1]); }
        }
        return (false);
      }

    },
    // 定位
    getLocation () {
      const that = this
      AMap.plugin(['AMap.Geolocation', 'AMap.Geocoder'], function () {
        let p1
        let p2
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 100,
        })
        var geocoder = new AMap.Geocoder({
        })

        geocoder.getLocation(that.hotelMsg.hotelName, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            // result中对应详细地理坐标信息
            // console.log(result)
            const { geocodes } = result
            p2 = [geocodes[0].location.lng, geocodes[0].location.lat]
          }
        })
        geolocation.getCurrentPosition(function (status, result) {
          if (status == 'complete') {
            onComplete(result)
          } else {
            onError(result)
          }
        });

        function onComplete (data) {
          // data是具体的定位信息
          // console.log(data)
          const { position } = data
          p1 = [position.lng, position.lat];
          var dis = AMap.GeometryUtil.distance(p1, p2);
          // console.log(typeof (dis))
          if (dis > 100) {
            that.locationShow = true
          }
        }

        function onError (data) {
          // 定位出错
          console.log(data)
        }
      })
    },
    getLocationNew() {
      return new Promise((resolve, reject) => {
        AMap.plugin('AMap.Geolocation', () => {
          var geolocation = new AMap.Geolocation({
            enableHighAccuracy: true, // 是否使用高精度定位，默认:true
            timeout: 10000,          // 超过10秒后停止定位，默认：5s
            buttonPosition: 'RB',     // 定位按钮的停靠位置
            buttonOffset: new AMap.Pixel(10, 20), // 定位按钮距离对应角落的距离
            zoomToAccuracy: true,    // 定位成功后是否自动调整地图视野到定位点
          });
          geolocation.getCurrentPosition((status, result) => {
            if (status == 'complete') {
              resolve({
                longitude: result.position.lng,
                latitude: result.position.lat,
              });
            } else {
              if (result.message.includes('permission')) {
               this.locationErr='refuse'

              }
              // location.reload()
              reject(result.message);
            }
          });
        });
      });
    },
    goPublic () {
      window.location.href = `https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=${this.hotelMsg.appid}#wechat_redirect`
    },
  }
};
</script>

<style scoped lang="scss">
.home {
  width: 100%;
  height: 100vh;
  background: url("../../assets/back.png") no-repeat;
  background-size: 100% 100%;
  color: #464b64;
  position: relative;
  .bsTitle{
    padding-top: 23px;
    h3 {
      line-height: 1;
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      padding: 0 5px;
      text-shadow: 2px 2px 2px rgba(16, 27, 56, 0.54);
    }
    .locationEn{
      font-size: 20px;
      margin-top: 7px;
    }
    .location {
      font-size: 24px;
      margin-top: 7px;
    }
  }
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 21px;
    > div {
      margin-right: 20px;
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      padding: 0 10px;
      text-shadow: 0px 2px 0px rgba(16, 27, 56, 0.54);
    }
    .location {
      font-size: 22px;
      margin-top: 15px;
    }
  }
  .show {
    background: #303f9c url("../../assets/back1.jpg") no-repeat;
    width: 85vw;
    height: 100%;
    margin: 0 auto;
    background-size: 100% 100%;
    -webkit-text-size-adjust: 100% !important;
    text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
  }
  .content {
    padding: 0 30px 0 23px;
    height: 78vh;
    box-sizing: border-box;
    position: absolute;
    top: 17vh;
    // border: 1px solid #7d7d7d;
    ::v-deep.el-tabs {
      height: 100%;
      // box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.5) inset;
      box-shadow: none;
      border: none;
      border-radius: 8px;
      .el-tabs__header {
        padding-top: 20px;
        height: 50%;
        background: none;
        border-radius: 8px;
        margin: 0;
        border: none;
        .el-tabs__item {
          padding: 10px;
          margin-left: 5px;
          margin-top: 0;
          height: initial;
          line-height: 1;
        }
        .is-active {
          border: 1px solid #d1dbe5;
          border-right: none;
          border-radius: 5px 0 0 5px;
          color: #333;
          box-shadow: 0 2px 2px 0 rgba(109, 109, 109, 0.3) inset;
        }
        // width: 20%;
      }
      .el-tabs__content {
        border: 1px solid #d1dbe5;
        box-shadow: 0 2px 2px 0 rgba(109, 109, 109, 0.3) inset;
        height: 97%;
        padding: 6px;

        border-radius: 9px;
        // width: calc(100% - 69px);
        // padding: 6px;
        .el-tab-pane {
          height: 100%;
        }

        //   // >
        //   //
        //   //

        // }
        // width: 100%;
      }
    }
    .item {
      width: calc(100%);
      margin-top: 10px;
      > p {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        i {
          display: block;
          width: 5px;
          height: 14px;
          background: #236df5;
          border-radius: 5px;
          margin-right: 4px;
        }
      }
      ul {
        font-size: 11px;
        padding: 3px 0 19px 3px;
        border-bottom: 2px dashed #7d7d7d;
        li {
          margin-top: 6px;
          display: flex;
          // align-items: center;
          p {
            margin-left: 21px;
            color: #236df5;
            .el-icon-paperclip {
              margin-right: 5px;
              // font-size: 28px;
              font-weight: 600;
            }
          }
          .adrress {
            display: block;
            // width: 40px;
            white-space: nowrap;
            // margin-right: 10px;
          }
          .sitename {
            display: block;
            // width: 65px;
            // margin-right: 10px;
            white-space: nowrap;
          }
          .othername {
            display: block;
            // width: 94px;
            white-space: nowrap;
            // margin-right: 10px;
          }
        }
      }
      .clean {
        padding: 10px 0 5px;
        line-height: 1.5;
      }
      .health {
        margin-top: 8px;
        p {
          font-size: 11px;
          margin-bottom: 8px;
          line-height: 1.5;
        }
      }
      ::v-deep.el-table {
        width: 100%;
      }
    }
    ::v-deep .el-table .el-table__header-wrapper{
        .el-table__header{
          .el-table__cell{
            padding: 0 !important
          }
        }
      }
    ::v-deep.el-table {
      margin-top: 10px;
      font-size: 12px;
      width: 240px;
  
      .el-table__cell {
        padding: 0 !important;
      }
      th.el-table__cell {
        background: rgb(240, 240, 240);
        color: #333;
      }
      .cell {
        padding: 0;
        text-align: center;
      }
     
    }
    ::v-deep .el-table__body-wrapper,.is-scrolling-none {
        height:73px !important
      }
    ::v-deep.el-empty {
      .el-empty__description {
        display: none;
      }
      .emptybottom {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        color: #999;
        p {
          margin-right: 16px;
        }
      }
    }
    .empty {
      p {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 700;
        color: #999;
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 12px;
    width: 100%;
    font-size: 14px;
    color: #fefefe;
    text-align: center;
  }
  .footerEn{
    position: fixed;
    bottom: 12px;
    width: 100%;
    font-size: 13px;
    color: #fefefe;
    text-align: center;
  }
  .goPublic {
    width: 40px;
    height: 40px;
    background: #236df5;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
    font-size: 8px;
    color: #fefefe;
    position: absolute;
    bottom: 150px;
    right: 28px;
  }
  .other {
    padding-left: 10px;
    .switch{
      display: flex;
      justify-content: center;
      align-items: center;
      .switchGroup{
      display: flex;
      justify-content:space-around;
      align-items: center;
      width: 120px;
      height: 30px;
      border-radius: 54px;
      background: #eff2f6;

      .switchNums {
        border-radius: 54px;
        background: #eff2f6;
        padding: 0 13px;
      //  width: 112rpx;
      //  height: 54rpx;
       line-height: 26px;
      //  border-radius: 54rpx;
      //  text-align: center;
      //  color: #C1C1C1;
      color: #333;
      font-size:12px;
      //  font-weight: bold;
      }
      .currentNum {
        background: #fff;
        line-height: 26px;
        color: #4883f6;
        font-size:12px;
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
        transform:translate(3px,0)
       }
    }
   
    }
    
  }
}
.homeErr{
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background: url("../../assets/back.png") no-repeat;
  background-size: 100% 100%;
  color: #464b64;
  position: relative;
  padding:0 40px ;
  div{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 18px;
    p{
      text-align: center;
      margin: 3px 0;
    }
  }
}
</style>
